import React from "react";
import logo from "../assets/img/logo-blanco-plano.png";

export default class Mantenimiento extends React.Component  {
    render() {
        return (
            <div className="account-pages my-5 pt-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-7">
                            <div className="text-center mb-5">
                                <div className="mb-5">
                                    <img src={logo} height="60" alt="logo" />
                                </div>
                                <h4 className="mt-4" style={{color:"#fff"}}>Mantenimiento programado</h4>
                                <p style={{color:"#fff"}}>A fin de mejorar la calidad en el servicio, el sistema de trámite listo se encuentra bajo mantenimiento programado.
                                    Volverá a estar disponible lo antes posible.</p>
                                <p style={{color:"#fff"}}>Para cualquier consulta puede dirigirse a consulado@consuladoperusevilla.es</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}