export default function PassWorker() {

    const getUrl = () => {
        if(process.env.NODE_ENV === "development") {
            return "http://localhost:8081/";
        } else {
            return "https://admin.consuladoperusevilla.es/";
        }
    }

    // eslint-disable-next-line
    self.onmessage = function (e) {
        let tag = e.data.tag;
        let token = e.data.token;
        let url = getUrl() + "v1/tramite/pasaporte/" + tag;
        let xhr = new XMLHttpRequest();
        xhr.open("GET",url);
        xhr.setRequestHeader("G-TOKEN", token);
        xhr.send();

        xhr.onload = function() {
            if(xhr.status === 200 || xhr.status === 404) {
                let ready = xhr.status === 200;
                if(ready) {
                    let data = JSON.parse(xhr.response);
                    // eslint-disable-next-line no-restricted-globals
                    self.postMessage({
                        error: false,
                        ready: true,
                        tag: tag,
                        recogido: data.recogido
                    });
                } else {
                    // eslint-disable-next-line no-restricted-globals
                    self.postMessage({
                        error: false,
                        ready: false,
                        tag: tag
                    });
                }
            } else {
                // eslint-disable-next-line no-restricted-globals
                self.postMessage({
                    error: true,
                    ready: false,
                    tag: tag
                });
            }
        };

        xhr.onerror = function () {
            console.log("Error enviando la solicitud");
            // eslint-disable-next-line no-restricted-globals
            self.postMessage({
                error: true,
                ready: false,
                tag: tag
            });
        };
    };
}