import React from "react";
import LoginForm from "../../components/admin/loginForm";
import {withTranslation} from "react-i18next";
import Warning from "../../components/alerts/Warning";
import LocalStorageService from "../../ports/localStorage/localStorageService";

class Login extends React.Component {
    render() {
        const no_link = "#";
        if(LocalStorageService.getUserToken() !== null) {
            window.location.assign("/admin");
        }
        return (
            <div className="account-pages my-5 pt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="text-center mb-5">
                                <a href={no_link} className="logo"><h4 class="mb-0 font-size-18" style={{color:"#fff"}}>Consulado General del Perú en Sevilla</h4></a>
                                <h5 className="font-size-16 text-white-50 mb-4">{this.props.t("admin.pages.login.maintitle")}</h5>
                            </div>
                        </div>
                    </div>
                    <Warning message={this.props.error} />
                    <LoginForm />
                </div>
            </div>
        );
    }
}

export default withTranslation()(Login);