import {withTranslation} from "react-i18next";
import React from "react";
import LocalStorageService from "../ports/localStorage/localStorageService";

class Menu extends React.Component {
    changeTramite(tramite) {
        LocalStorageService.changeTramite(tramite);
        window.location.reload();
    }
    render() {
        const link = "#";
        return(
            <div className="topnav">
                <div className="container-fluid">
                    <nav className="navbar navbar-light navbar-expand-lg topnav-menu">

                        <div className="collapse navbar-collapse" id="topnav-menu-content">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <a className="nav-link" href={link} onClick={() => this.changeTramite("dni")}>
                                        <i className="mdi mdi-smart-card mr-2"/> {this.props.t("components.menu.id")}
                                    </a>
                                </li>

                                <li className="nav-item">
                                    <a className="nav-link" href={link} onClick={() => this.changeTramite("pasaporte")}>
                                        <i className="mdi mdi-passport mr-2"/>{this.props.t("components.menu.passport")}
                                    </a>
                                </li>

                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        );
    }
}

export default withTranslation()(Menu);