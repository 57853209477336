import React from "react";
import {withTranslation} from "react-i18next";
import ReCaptchaV2 from "react-google-recaptcha";
import TramiteResponse from "./tramiteResponse";
import Toastify from "../alerts/Toastify";
import {toast} from "react-toastify";
import WebWorker from "../../workers/webWorker";
import TramiteWorker from "../../workers/passWorker";
import etiqueta from "../../assets/img/etiqueta-compressor.png";

class Pasaporte extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tag: null
        }
        this.attemptQuery = this.attemptQuery.bind(this);
        this.onCaptcha = this.onCaptcha.bind(this);
    }

    changePass(pass) {
        this.setState({
            tag: pass
        });
    }

    onCaptcha(e) {
        this.setState({
            captcha: e
        });
    }

    componentDidMount() {
        toast.configure();
        this.worker = new WebWorker(TramiteWorker);
        this.worker.onmessage = ev =>  {
            console.debug("Return message: ");
            console.debug(ev.data);
            let {ready, error, tag} = ev.data;
            let recogido = 'null';
            if(ev.data.recogido !== null && ev.data.recogido !== undefined) {
                recogido = ev.data.recogido;
                recogido = new Date(recogido.seconds * 1000);
                recogido = recogido.getDate() + "/" + recogido.getMonth() + "/" + recogido.getFullYear();
            }
            this.setState({
                response: {
                    status: "response",
                    data: {
                        error: error,
                        ready: ready,
                        documento: tag,
                        recogido: recogido
                    }
                }
            });
        }
    }

    validatePass(pass) {
        if(pass === "") {
            return "Debe introducir un número de etiqueta";
        }
        if(!pass.match("^[M][R][E][A][C]+[0-9]+$")) {
            return "La etiqueta parece tener un formato incorrecto. Debe iniciar con MREAC seguido de números solamente.";
        }
        return null;
    }

    attemptQuery() {
        // eslint-disable-next-line no-undef
        grecaptcha.reset();
        let token = this.state.captcha;
        let tag = this.state.tag;
        if(token != null) {
            let validate = this.validatePass(tag);
            if(validate == null) {
                this.changePass("MREAC");
                this.setState({
                    response: {
                        status: "query"
                    }
                })
                this.worker.postMessage({
                    tag: tag,
                    token: token
                });
            } else {
                Toastify.error(toast, validate);
            }
        } else {
            Toastify.error(toast,"Por seguridad, debe marcar No soy un robot para continuar.");
        }
    }

    render() {
        let value = this.state.tag;
        let disabled = false;
        if(this.state.response != null && this.state.response.status === "query") disabled = true;
        if (value == null) value = "MREAC";
        return (
            <div className="container-fluid">

                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                            <h4 className="mb-0 font-size-18" style={{color: "#ffff"}}>{this.props.t("components.pasaporte.title")}</h4>

                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-3"/>
                    <div className="col-xl-6">
                        <div className="card">
                            <div className="card-body p-4">
                                <div className="p-2">
                                    <h5 className="mb-5 text-center">Inserte su número de etiqueta para saber si está listo para ser entregado.</h5>
                                    <p>El número de etiqueta es el código que se sitúa debajo del código de barras de su comprobante de pago. Inicia con el prefijo MREAC.</p>
                                    <form>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group mb-4">
                                                    <label htmlFor="dni">Etiqueta</label>
                                                    <input type="text" className="form-control" id="dni"
                                                           placeholder="MREACXXXXXXX" value={value} onChange={(e) => this.changePass(e.target.value)} />
                                                </div>
                                                <div className="mt-4">
                                                    <ReCaptchaV2 sitekey="6LcwJvIhAAAAAOIutXLyJ9EJLvW4Eofu2cecCPvh" onChange={this.onCaptcha} />
                                                </div>
                                                <div className="mt-4">
                                                    <button
                                                        className="btn btn-success btn-block waves-effect waves-light"
                                                        type="button" onClick={() => this.attemptQuery()} disabled={disabled}>Consultar
                                                    </button>
                                                    <p/>
                                                    <button type="button"
                                                            className="btn btn-success btn-block waves-effect waves-light"
                                                            data-toggle="modal" data-target="#myModal">Ver etiqueta de muestra
                                                    </button>

                                                    <div id="myModal" className="modal fade" tabIndex="-1"
                                                         role="dialog" aria-labelledby="myModalLabel"
                                                         aria-hidden="true" style={{display:"none"}}>
                                                        <div className="modal-dialog">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title mt-0"
                                                                        id="myModalLabel">Etiqueta de muestra</h5>
                                                                    <button type="button" className="close"
                                                                            data-dismiss="modal" aria-label="Close">
                                                                        <span aria-hidden="true">×</span>
                                                                    </button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    <img src={etiqueta} border="0" style={{maxWidth: "70%"}} alt="etiqueta" />
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button"
                                                                            className="btn btn-secondary waves-effect"
                                                                            data-dismiss="modal">Cerrar
                                                                    </button>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <TramiteResponse response={this.state.response} documento={"Pasaporte"} />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="col-xl-3"/>
                </div>
            </div>
        );
    }
}

export default withTranslation()(Pasaporte);