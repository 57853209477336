import React from "react";
import {withTranslation} from "react-i18next";
import TramiteWorker from "../../workers/dniWorker";
import Toastify from "../alerts/Toastify";
import {toast} from "react-toastify";
import WebWorker from "../../workers/webWorker";
import ReCaptchaV2 from 'react-google-recaptcha'
import DniResponse from "./dniResponse";

class DNI extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dni: null,
            response: null,
            captcha: null
        }
        this.attemptQuery = this.attemptQuery.bind(this);
        this.onCaptcha = this.onCaptcha.bind(this);
    }
    componentDidMount() {
        toast.configure();
        this.worker = new WebWorker(TramiteWorker);
        this.worker.onmessage = ev =>  {
            console.debug("Return message: ");
            console.debug(ev.data);
            let {found, error, data} = ev.data;
            this.setState({
                response: {
                    status: "response",
                    data: {
                        error: error,
                        found: found,
                        data: data
                    }
                }
            });
        }
    }

    validateDNI(dni) {
        let numbers = /^[0-9]+$/;
        if(dni == null || dni === "") {
            return "Debe introducir un DNI válido para continuar.";
        }
        if(!dni.match(numbers)) return "El DNI sólo contiene números.";
        return null;
    }

    onCaptcha(e) {
        this.setState({
            captcha: e
        });
    }

    attemptQuery() {
        // eslint-disable-next-line no-undef
        grecaptcha.reset();
        let token = this.state.captcha;
        let dni = this.state.dni;
        if(token != null) {
            if(dni != null) dni = dni.trim();
            let validate = this.validateDNI(dni);
            if(validate == null) {
                this.changeDNI("");
                this.setState({
                    response: {
                        status: "query"
                    }
                })
                this.worker.postMessage({
                    dni: dni,
                    token: token
                });
            } else {
                Toastify.error(toast, validate);
            }
        } else {
            Toastify.error(toast,"Por seguridad, debe marcar No soy un robot para continuar.");
        }
    }

    changeDNI(dni) {
        this.setState({
            dni: dni
        });
    }

    render() {
        let value = this.state.dni;
        let disabled = false;
        if(this.state.response != null && this.state.response.status === "query") disabled = true;
        if (value == null) value = "";
        return(
            <div className="container-fluid">
                <div className="row">
                    <div className="col-3"/>
                    <div className="col-6">
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                            <h4 className="mb-0 font-size-18" style={{color: "#ffff"}}>{this.props.t("components.dni.title")}</h4>

                        </div>
                    </div>
                    <div className="col-3"/>
                </div>
                <div className="row">
                    <div className="col-xl-3"/>
                    <div className="col-xl-6">
                        <div className="card">
                            <div className="card-body p-4">
                                <div className="p-2">
                                    <h5 className="mb-5 text-center">Inserte su número de DNI para saber si está listo para ser entregado.</h5>
                                    <p>Importante: Si su DNI empieza con el número “0” (cero) empiece escribiendo el 2do número de su DNI.</p>
                                    <p>Ejemplo: DNI 09876543, escriba en el buscador: “9876543”</p>
                                    <form>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group mb-4">
                                                    <label htmlFor="dni">DNI o ficha registral</label>
                                                    <input type="text" className="form-control" id="dni"
                                                           placeholder="99999999" value={value} onChange={(e) => this.changeDNI(e.target.value)} />
                                                </div>
                                                <div className="mt-4">
                                                    <ReCaptchaV2 sitekey="6LcwJvIhAAAAAOIutXLyJ9EJLvW4Eofu2cecCPvh" onChange={this.onCaptcha} />
                                                </div>
                                                <div className="mt-4">
                                                    <button
                                                        className="btn btn-success btn-block waves-effect waves-light"
                                                        type="button" onClick={() => this.attemptQuery()} disabled={disabled}>Consultar
                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <DniResponse response={this.state.response} documento={"DNI"} />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="col-xl-3"/>
                </div>
            </div>
        );
    }
}
export default withTranslation()(DNI);