import React from "react";

export default class Danger extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: props.message
        }
    }
    render() {
        let message = this.state.message;
        if(message != null && message !== "") {
            return(
                <div className="alert alert-danger" role="alert">
                    {this.state.message}
                </div>
            );
        } else {
            return (<p />);
        }

    }
}