import React from "react";
import Navbar from "../components/navbar";
import Menu from "../components/menu";
import Footer from "../components/footer";
import DNI from "../components/tramites/dni";
import Pasaporte from "../components/tramites/pasaporte";
import LocalStorageService from "../ports/localStorage/localStorageService";
import BackendConnectionService from "../ports/backend/backendConnectionService";
import Info from "../components/alerts/Info";
import Warning from "../components/alerts/Warning";
import Danger from "../components/alerts/Danger";

export default class Home extends React.Component {

    constructor(props) {
        super(props);
        if(LocalStorageService.getTramite() == null) {
            LocalStorageService.changeTramite("dni");
        }
        this.state = {
            message: null,
            type: null
        }
    }

    componentDidMount() {
        BackendConnectionService.getPublicMetadata()
            .then((response => {
                let {message, type} = response.data;
                this.setState({
                    message: message,
                    type: type
                });
            }))
    }

    availableTramites() {
        return new Map([
            ["dni", <DNI/>],
            ["pasaporte", <Pasaporte />]
        ]);
    }

    renderTramite() {
        let tramite = LocalStorageService.getTramite();
        return this.availableTramites().get(tramite);
    }

    renderAviso(message, type) {
        if(message !== "" && message != null) {
            if(type === 1) {
                return <Info message={message} />
            } else if (type === 2) {
                return <Warning message={message} />
            } else {
                return <Danger message={message}/>
            }
        }
    }

    render() {
        let {message, type} = this.state;
        let aviso = this.renderAviso(message,type);
        return(
                <div id="layout-wrapper">
                    <Navbar />
                    <Menu />

                <div className="main-content">

                    <div className="page-content">
                        {aviso}
                        {this.renderTramite()}
                    </div>

                    <Footer />
                </div>
        </div>
        );
    }
}